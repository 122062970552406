import type { HTMLAttributes } from 'react';
import { forwardRef } from 'react';

import { cn } from '~/libs/utils';

export const DescriptionItemTitle = forwardRef<HTMLElement, HTMLAttributes<HTMLElement>>((props, ref) => (
  <dt ref={ref} {...props} />
));
DescriptionItemTitle.displayName = 'DescriptionItemTitle';

export const DescriptionItemDescription = forwardRef<HTMLElement, HTMLAttributes<HTMLElement>>(
  ({ className, ...props }, ref) => <dd className={cn('text-end', className)} ref={ref} {...props} />
);
DescriptionItemDescription.displayName = 'DescriptionItemDescription';

export const DescriptionItem = forwardRef<HTMLDListElement, HTMLAttributes<HTMLDListElement>>(
  ({ className, ...props }, ref) => (
    <dl className={cn('flex items-baseline justify-between', className)} ref={ref} {...props} />
  )
);
DescriptionItem.displayName = 'DescriptionItem';

export const Description = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div className={cn('my-2 flex flex-col gap-2 p-2', className)} ref={ref} {...props} />
  )
);
Description.displayName = 'Description';
